.daily-challenge {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 7px;
  gap: 5px;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  
  background-color: var(--white);

  box-shadow: 0 4px 0 0 #939393;

  transition: all 0.1s ease-in-out;

  container-type: inline-size;
}

.daily-challenge:hover {
  background-color: #b5b5b5;
}

.daily-challenge:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
}

.daily-challenge-selected {
  border: 2px solid var(--white);
  background-color: var(--background-light);
}

.daily-challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daily-challenge-title {
  display: flex;
  align-items: center;
}

.daily-challenge-title h3 {
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: var(--ice-blue);
}

.daily-refresh {
  display: flex;
  align-items: center;
  color: #b5b5b5;
  gap: 3px;
}

.daily-refresh span {
  flex-wrap: nowrap;
  font-size: 14px;
}

.refresh-icon {
  color: #b5b5b5;
}

.daily-challenge-content {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.daily-challenge-content p {
  color: var(--background-main);
}

.daily-challenge-region {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
}

.daily-challenge-region p {
  font-size: 12px;
}

.daily-challenge-region-bold {
  font-weight: 600;
}

.daily-challenge-selected .daily-challenge-region p{
  color: var(--white);
}

.daily-challenge-flag-container {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--background-main);
}

.daily-challenge-flag-container-selected {
  color: var(--white);
}

.daily-challenge-flag {
  border: 2px solid rgb(166, 200, 221);
  background-color: rgb(36, 99, 131);
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 20px;
}

@container (max-width: 250px) {
  .daily-challenge-header {
    gap: 3px;
    flex-direction: column;
    align-items: center;
  }
}