.BasicButton, .LogoutButton, .LoginButton, .SubmitButton, .SubmitButton2, .EASY, .MEDIUM, .HARD{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 10px;

  border: none;

  transition: all 0.1s ease-in-out;
}

/* BASIC BUTTON */
.BasicButton{
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  
  color: var(--background-main);
  background-color: var(--white);

  box-shadow: 0 4px 0 0 #939393;
}

.BasicButton:hover{
  background-color: #b5b5b5;
}

.BasicButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}

/* SUBMIT BUTTON */
.SubmitButton{
  padding: 10px 12px;

  font-size: 15px;
  font-weight: 500;

  width: 100%;
  
  color: var(--white);
  background-color: #555555;

  box-shadow: 0 4px 0 0 #464646;
}

.SubmitButton:hover{
  background-color: rgb(77, 78, 82);
}

.SubmitButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #464646;
}

/* SUBMIT BUTTON */
.SubmitButton2{
  padding: 10px 12px;

  font-size: 15px;
  font-weight: 500;

  width: 100%;
  
  color: var(--background-main);
  background-color: var(--white);

  box-shadow: 0 4px 0 0 #939393;
}

.SubmitButton2:hover{
  background-color: #b5b5b5;
}

.SubmitButton2:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}

/* LOGOUT BUTTON */
.LogoutButton{
  padding: 10px;
  font-size: 15px;
  font-weight: 500;

  width: 100%;
  
  color: var(--white);
  background-color: var(--danger-red);

  box-shadow: 0 4px 0 0 #742929;
}

.LogoutButton:hover{
  background-color: #923434;
}

.LogoutButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #742929;
}

/* EASY BUTTON */
.EASY{
  width: 100%;
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  
  color: var(--white);
  background-color: #858584;
  box-shadow: 0 4px 0 0 #6b6b6c;
} 

.EASY.selectedDifficulty{
  background-color: var(--success-green);
  box-shadow: 0 4px 0 0 #385839;
}

.EASY:hover{
  background-color: #39743b;
}

.EASY:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #39743b;
}

/* MEDIUM BUTTON */
.MEDIUM{
  width: 100%;
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  
  color: var(--white);
  background-color: #858584;
  box-shadow: 0 4px 0 0 #6b6b6c;
}

.MEDIUM.selectedDifficulty{
  background-color: var(--warning-yellow);
  box-shadow: 0 4px 0 0 #92743b;
}

.MEDIUM:hover{
  background-color: #92743b;
}

.MEDIUM:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #92743b;
}

/* HARD BUTTON */
.HARD{
  width: 100%;
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  
  color: var(--white);
  background-color: #858584;
  box-shadow: 0 4px 0 0 #6b6b6c;
}

.HARD.selectedDifficulty{
  background-color: var(--danger-red);
  box-shadow: 0 4px 0 0 #923434;
}

.HARD:hover{
  background-color: #923434;
}

.HARD:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #923434;
}

/* LOGIN BUTTON */
.LoginButton{
  min-height: 40px;
  min-width: 40px;
  padding: 7px;
  font-size: 15px;
  font-weight: 400;

  text-wrap: nowrap;
  background-color: var(--success-green);
  color: var(--white);

  box-shadow: 0 4px 0 0 #385839;
}

.LoginButton:hover{
  background-color: #39743b;
}

.LoginButton:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #39743b;
}

@media (max-width: 850px){
  .BasicButton{
    font-size: 12px;
  }
}
