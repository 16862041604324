.SpecialButton{
  position: relative;

  background-color: rgb(58, 206, 255);
  color: var(--white);

  border: 3px solid rgb(35, 82, 124);
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 7px;
  padding: 13px 25px;
  width: 100%;

  font-size: 18px;
  font-weight: 500;

  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 rgb(35, 82, 124);
}

.SpecialButton:hover {
  background-color: rgb(37, 163, 206);
}

.SpecialButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 rgb(0, 97, 255);
}

@media (max-width: 800px) {
  .SpecialButton{
    font-size: 15px;
  }
}

@media (max-width: 630px) {
  .SpecialButton{
    font-size: 18px;
  }
}