
.AYRLogo{
  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.AYRLogo img{
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

h1{
  font-size: 30px;
  font-weight: 700;
  text-align: center;

  transition: all 0.5s ease;
}

@media (max-width: 850px) {
  h1{
    font-size: 4vw;
  }
}

@media (max-width: 630px){
  h1{
    font-size: 30px;
  }
}

@media (max-width: 450px){
  .AYRLogo img{
    display: none;
  }
}