.controlButton {
  position: relative;
  display: flex;
  gap: 5px;

  padding: 10px 10px;
  border-radius: 10px;

  font-weight: bold;
  
  color: var(--background-main);
  background-color: var(--white);

  border: none;

  transition: all 0.1s ease-in-out;

  box-shadow: 0 4px 0 0 #939393;
}

.controlButton:hover {
  background-color: #b5b5b5;
}

.controlButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.button-text {
  position: relative;
  top: -23px;
}

.controlButton p{
  color: var(--background-main);
  font-weight: 500;
  z-index: 1;
}

.controlButtonLocked {
  position: relative;
  display: flex;
  gap: 5px;

  padding: 10px 10px;
  border-radius: 10px;

  font-weight: bold;
  
  color: var(--background-main);
  background-color: var(--background-light);

  border: none;


  cursor: pointer;

  transition: all 0.1s ease-in-out;

  box-shadow: 0 4px 0 0 #202020;
}

.controlButtonLocked p{
  color: var(--background-main);
  font-weight: 500;
  z-index: 1;
}

@media (max-width: 500px) {
  .controlButton p{
    display: none;
  }
}