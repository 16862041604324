.ProfileModal{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  margin-top: -30px;
  z-index: 5;
}

.ProfileModal *{
  box-sizing: border-box;
}

.profile-container{
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;

  background-color: var(--background-main);
  color: var(--white);
  padding: 20px;
  border-radius: 10px;

  width: 400px;

  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.25)
}

.profile-container h3{
  font-size: 18px;
  font-weight: 600;
}

.profile-top{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  padding-bottom: 10px;
}

.profile-top-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

.profile-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.profile-row.points{
  background-color: var(--white);
  padding: 10px;
  border-radius: 10px;
}

.profile-username-element{
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 40px;
  padding: 5px 5px 5px 10px;

  border-radius: 10px;
}

.profile-container input{
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 500;

  border-radius: 10px;

  outline-color: var(--success-green);
}

.profile-container p{
  font-weight: 500;
  color: var(--background-main);
}

.EditButton, .ConfirmButton, .clearButton, .profile-top button {
  height: 40px;
  min-width: 40px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 7px;

  border-radius: 10px;

  border: none;

  transition: all 0.1s ease-in-out;
}

.EditButton, .profile-top button{
  color: var(--background-main);
  background-color: var(--white);

  box-shadow: 0 4px 0 0 #939393;
}

.EditButton:hover, .profile-top button:hover{
  background-color: #b5b5b5;
}

.EditButton:active, .profile-top button:active  {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}

.ConfirmButton{
  background-color: var(--success-green);
  color: var(--white);

  box-shadow: 0 4px 0 0 #385839;
}

.ConfirmButton:hover{
  background-color: #39743b;
}

.ConfirmButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #39743b;
}

.clearButton {
  color: var(--white);
  background-color: var(--danger-red);

  box-shadow: 0 4px 0 0 #742929;
}

.clearButton:hover{
  background-color: #923434;
}

.clearButton:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #742929;
}

@media (max-width: 500px) {
  .profile-container{
    margin: 20px;
    width: 100%;
  }
}