.progress-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 7px;
  border-radius: 100px;

  transition: width 0.3s ease;

  position: relative;

  background-color: var(--background-light);
}

.progress-text-bg{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 5px 12px;
  border-radius: 20px;

  z-index: 2;
}

.progress-fillup{
  position: absolute;

  display: flex;
  justify-content: center;

  height: 100%;
  border-radius: 100px;

  z-index: 1;

  transition: width 0.3s ease;
}

.progress-fillup-light{
  position: absolute;
  top: 25%;
  border-radius: 99px;
  
  height: 6px;
  background-color: rgb(255, 255, 255);
  opacity: 0.10;

  width: calc(100% - 17px);

  z-index: -1;
}

#progress-points{
  text-align: end;
}

#progress-status{
  z-index: 0;
  font-weight: 500;
  font-size: 15px;
  color: var(--white);
}

.video{
  position: absolute;
  right: -35px;
  top: -25px;

  opacity: 1;

  width: 80px;
  height: 80px;
}

.next-level-points{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}

.next-level-points img{
  width: 19px;
  height: 19px;
}

.progress-text-bg p{
  color: var(--white);
  font-weight: 550 !important;
}

@media (max-width: 850px) {
  .progress-bar{
    width: 100% !important;
    min-width: 250px;
  }

  #progress-status, #progress-points, .progress-bar p{
    font-size: 12px;
  }
}

@media (max-width: 630px) {
  #progress-status, #progress-points, .progress-bar p{
    font-size: 18px;
  }

  .next-level-points img{
    width: 22px;
    height: 22px;
  }
}
