.AlertElement{
  position: fixed;

  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  top: 5%;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 10px;
  padding: 20px;

  color: var(--white);
  font-size: 16px;
  font-weight: 450;

  z-index: 9999;

  transition: opacity 0.3s ease-in-out;
  animation: slideInFromTop 0.5s ease forwards;
}

.AlertElement p{
  text-align: center;
}

.red{
  border: 2px solid var(--danger-red);
  background-color: #ca8988;
}

.green{
  border: 2px solid var(--success-green);
  background-color: #76a177;
}

.blue{
  border: 2px solid #27A8E3;
  background-color: #6b95a8;
}

@media (max-width: 800px) {
  .AlertElement{
    min-width: 280px;
  }
}

@keyframes slideInFromTop {
  0% {
    top: -60px;
  }
  100% {
    top: 5%;
  }
}