.CustomGamemode {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 10px;
  max-width: 800px;
  margin: 0 auto;
  color: var(--background-main);

  background-color: var(--white);
  border-radius: 15px;
}

.CustomGamemode h2 {
  font-size: 22px;
  text-align: center;
  color: var(--background-main);
}

.CustomGamemode p {
  text-align: center;
  color: var(--background-main);
}

.custom-gamemode-instructions {
  color: var(--background-main);
  text-align: center;
}

.countries-count {
  color: var(--background-main);
  text-align: center;
}

.countries-grid {
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: var(--background-light);
  padding: 10px;
  border-radius: 10px;
}

.country-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--white);
  border: 2px solid transparent;
}

.country-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 5px;
  border: 2px solid rgb(166, 200, 221);
}

.country-item span {
  font-size: 12px;
  text-align: center;
}

.country-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.difficulty-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.custom-gamemode-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 600px) {
  .countries-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  
  .country-item img {
    width: 30px;
    height: 30px;
  }
  
  .country-item span {
    font-size: 10px;
  }
} 