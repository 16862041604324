.DailyChallengeAnswers {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

@media (max-width: 1050px) {
  .DailyChallengeAnswers{
    margin-top: 5px;
  }
}

.answer-square {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.answer-square.correct {
  background-color: #4caf50;
}

.answer-square.incorrect {
  background-color: #f44336;
}

.answer-square.skipped {
  background-color: #9e9e9e;
}

/* Adjust for first, second, third places */
.first .DailyChallengeAnswers .answer-square.correct, 
.second .DailyChallengeAnswers .answer-square.correct, 
.third .DailyChallengeAnswers .answer-square.correct {
  background-color: #4caf50;
  border: 0.5px solid #fff;
}

.first .DailyChallengeAnswers .answer-square.incorrect, 
.second .DailyChallengeAnswers .answer-square.incorrect, 
.third .DailyChallengeAnswers .answer-square.incorrect {
  background-color: #f44336;
  border: 0.5px solid #fff;
}

.isUser .DailyChallengeAnswers .answer-square {
  border: 0.5px solid #fff;
} 