.ProfileElement{
  display: flex;
  flex-direction: column;
  gap: 13px;
  justify-content: flex-start;
  align-items: flex-end;

  position: relative;
}

.profile-section{
  display: flex;
  gap: 10px;
}

.profile-button{
  gap: 5px;

  max-width: 160px;
  height: 40px;
  width: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--white);
  color: var(--background-main);
  box-shadow: 0 4px 0 0 #939393;

  border: none;
  
  border-radius: 10px;
  padding: 5px 10px;

  transition: all 0.1s ease-in-out;
}

.profile-button p{
  color: var(--background-main);
}

.profile-button:hover {
  background-color: #b5b5b5;
}

.profile-button:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}
