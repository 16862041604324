.Choice{
  position: relative;

  height: 100%;
  width: calc(100% - 10px);
  min-width: 0;

  border-radius: 10px;
  padding: 5px 5px 20px 5px;

  aspect-ratio: 1/1;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--white);
  color: var(--background-main) !important;
  box-shadow: 0 4px 0 0 #939393;

  border: none;

  transition: all 0.1s ease-in-out;
}

.Choice:hover{
  background-color: #b5b5b5;
}

.Choice:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.Choice:active p, .Choice:hover p{
  background-color: #b5b5b5;
}

.correct{
  background-color: var(--success-green);
}

.Choice img{
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.Choice p{
  position: absolute;

  bottom: 4px;

  color: var(--background-main);
  background-color: var(--white);

  width: calc(100% - 6px);
  border-radius: 5px;

  text-align: center;
  font-size: 10px;
  font-weight: 550;

  transition: all 0.1s ease-in-out;
}

.Choice:hover p{
  color: var(--white);
}

@media (max-width: 900px){
  .Choice:hover{
    background-color: var(--white);
    color: var(--background-main);
  }

  .Choice:active p, .Choice:hover p{
    background-color: var(--white);
    color: var(--background-main);
  }
}