.difficulty-button{
  border-radius: 10px;

  border: none;
  padding: 10px;
  text-wrap: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

  font-weight: 400;
  font-size: 15px;

  height: 50px;
  width: 100%;

  color: var(--background-main);

  transition: all 0.1s ease-in-out;

  border: 2px solid transparent;

  position: relative;
}

.Hard{
  background-color: #B94A48;
  border-color: #f59c9b;
  color: var(--white);
  font-weight: 500;
}

.Medium{
  background-color: #cb9e4a;
  border-color: #eed5a6;
  color: var(--white);
  font-weight: 500;
}

.Easy{
  background-color: rgb(36, 131, 36);
  border-color: #afedaa;
  color: var(--white);
  font-weight: 500;
}

.info-button{
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.iconModal{
  position: absolute;
  display: flex;
  flex-direction: column;

  bottom: 50px;

  width: 100%;
  height: auto;
  
  padding: 10px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0px 0px 11px 6px rgba(0, 0, 0, 0.25);
  gap: 7px;

  z-index: 100;
}

.iconModal p{
  color: var(--background-main);
  font-size: 13px;
  font-weight: 400;
  text-wrap: wrap;
  text-align: start;
}