.FeedbackModal {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.6);

  width: 100vw;
  height: 100vh;

  z-index: 1000;
}

.FeedbackModal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;

  background-color: var(--background-main);
  color: var(--white);
  padding: 20px;
  border-radius: 10px;

  width: 380px;

  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.25)
}

.FeedbackModal-container h2 {
  font-size: 25px;
  font-weight: 700;
}

.feedback-description {
  font-size: 15px;
  text-align: center;
  background-color: var(--background-light);
  padding: 10px;
  border-radius: 7px;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.form-group label {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
}

.form-group label .optional-text {
  opacity: 0.5;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  background-color: var(--white);
  color: var(--background-main);
  font-size: 14px;
  resize: vertical;
}

.form-group textarea {
  min-height: 100px;
  max-height: 300px;
}

.contact-email {
  font-size: 12px;
  opacity: 0.6;
  text-align: center;
}

.contact-email a {
  text-decoration: underline;
}

.contact-email a:hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  .FeedbackModal-container {
    margin: 15px;
    width: auto;
    max-width: 380px;
  }
} 