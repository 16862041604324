.PremiumElement {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  font-weight: 600;
  border: none;
  height: 40px;
  border-radius: 10px;
  padding: 5px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  background-color: #f4dc29;
  color: var(--background-light);
  box-shadow: 0 4px 0 0 rgb(165, 148, 19);
}

.PremiumElement:hover {
  background-color: #c0ac19;
}

.PremiumElement:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 rgb(165, 148, 19);
}

.PremiumElement p{
  color: var(--background-light);
}