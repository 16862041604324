.Friend{
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  background-color: var(--white);
  border-radius: 7px;

  border: 2px solid transparent;
  
  padding: 5px;
  gap: 7px;
}

.Friend p, div{
  color: var(--background-main);
  font-size: 15px;
}

.friend-streak-points-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.is-user{
  border: 2px solid #2D7595;
  background-color: #1d3e49;
}

.is-user p{
  color: var(--white);
  font-weight: 500;
}

@media (max-width: 1240px) {
  .friend-streak-points-container{
    flex-direction: column;
    align-items: end;
    gap: 5px;
  }
}

@media (max-width: 1050px) {
  .LeaderboardMember, .Friend{
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .friend-streak-points-container{
    flex-direction: row;
    gap: 7px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .LeaderboardMember{
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
  }

  .friend-streak-points-container{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;

    width: unset;
  }
}

@media (max-width: 370px){
  .friend-streak-points-container{
    flex-direction: column;
    align-items: end;
    gap: 5px;
  }
}