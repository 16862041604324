.daily-challenge-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.daily-challenge-section-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding: 12px;
  border-radius: 10px;

  background-color: var(--background-main);
}

.daily-challenge-section-header p {
  color: var(--white);
}

.daily-challenge-players-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.daily-challenge-players-container p {
  color: var(--background-main);
  font-size: 18px;
}

.daily-challenge-players-count {
  font-weight: 600;
}

.share-emoji-pattern {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.daily-challenge-result p {
  color: var(--background-main);
}

.daily-challenge-result p span {
  font-weight: 700;
}

.daily-challenge-result {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}