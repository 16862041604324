.GameControlBar{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;

  justify-content: space-between;
  align-items: center;
}

.modalContainer{
  position: relative;
}

.skip-icon{
  width: 20px;
  height: 20px;
}

.GameControlBar .PointsStreak p{
  font-size: 25px;
  font-weight: 500;
  color: var(--white);
}

.GameControlBar .PointsStreak .levelDesign{
  font-size: 16px;
}

.streak-container {
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  position: relative;
}

.streak-saver {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  border-radius: 5px;
  gap: 3px;
  position: relative;
}

.streak-saver.available {
  color: rgb(110, 233, 34)
}

.streak-saver.used {
  color: rgb(95, 95, 95);
}

.skip-button-container {
  position: relative;
}

.skip-info-tooltip, .streak-saver-tooltip {
  position: absolute;
  top: -140px;
  right: 0;
  width: 200px;
  background-color: rgba(40, 44, 52, 0.95);
  border: 1px solid var(--white);
  border-radius: 8px;
  padding: 10px;
  z-index: 10;
  color: var(--white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fade-in 0.2s ease-in;
}

.streak-saver-tooltip {
  right: -60px;
  top: -140px;
}

.tooltip-header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 2px;
  color: var(--white);
}

.tooltip-header span {
  font-weight: bold;
  color: var(--white);
}

.skip-info-tooltip ul, .streak-saver-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skip-info-tooltip li, .streak-saver-tooltip li {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.user-type {
  font-weight: 500;
}

.skip-count {
  color: #61dafb;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 500px) {
  .GameControlBar .PointsStreak p{
    font-size: 20px;
    font-weight: 500;
    color: var(--white);
  }

  .streak-container{
    gap: 3px;
  }

  .streak-saver-icon{
    width: 20px !important;
    height: 20px !important;
  }
  
  .GameControlBar .PointsStreak img{
    width: 20px;
    height: 20px;
  }

  .streak-saver {
    font-size: 10px;
    padding: 2px 3px;
  }
  
  .skip-info-tooltip, .streak-saver-tooltip {
    width: 160px;
    font-size: 12px;
    right: -50px;
  }
  
  .streak-saver-tooltip {
    right: -80px;
  }
}