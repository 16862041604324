.ImageModal {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;

  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

.image-container{
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.ImageModal img {
  width: auto;
  max-height: 70%;
  max-width: 90vw;

  object-fit: cover;

  border-radius: 20px;

  border: 5px solid var(--white);
  opacity: 0;

  animation: fadeIn 0.5s ease-out forwards;
}

.CloseButton {
  gap: 10px;

  color: white;

  border: none;

  font-size: 18px;
  font-weight: 550;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;
  border-radius: 10px;

  border: none;

  color: var(--white);
  background-color: var(--danger-red);
  box-shadow: 0 4px 0 0 #742929;

  transition: all 0.1s ease-in-out;
}

.CloseButton:hover {
  background-color: #923434;
}

.CloseButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #742929;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}