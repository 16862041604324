

.Gamemode, .GamemodeLocked, .GamemodeSelected{
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  font-size: 15px;
  padding: 7px;

  border: 2px solid transparent;

  width: 100%;
}

.gamemodeContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  width: 100%;
}

.Gamemode p{
  text-align: start;
  color: var(--background-main);
}

.Gamemode{
  background-color: var(--white);
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;

  font-weight: 400;
}

.Gamemode:hover {
  background-color: #b5b5b5;
}

.Gamemode:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.GamemodeSelected{
  background-color: var(--background-light);
  color: var(--white);

  border: 2px solid rgb(216, 216, 216);

  transition: all 0.1s ease-in-out;

  box-shadow: 0 4px 0 0 rgb(169, 169, 169);

  font-weight: 500;
}

.settings-container .GamemodeSelected{
  padding: 10px;
}

.GamemodeSelected p{
  text-align: start;
  color: var(--white);
}

.GamemodeSelected:hover {
  background-color: rgb(216, 216, 216);
}

.GamemodeSelected:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 rgb(216, 216, 216);
}

.GamemodeSelected:disabled{
  box-shadow: none;
  cursor: unset;
}

.GamemodeSelected:hover:disabled{
  box-shadow: none;
  background-color: var(--background-light);
}

.GamemodeSelected:active:disabled{
  transform: unset;
  box-shadow: none;
  background-color: var(--background-light)
}

.GamemodeLocked{
  background-color: #2f2f2f ;
  color: #616568;

  border: 2px solid 2f2f2f;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 rgb(28, 28, 28);
}

.GamemodeLocked:hover {
  background-color: #444444;
}

.GamemodeLocked:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #2f2f2f;
}


.levelRegionContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.level{
  height: 22px;
}