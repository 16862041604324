.NotificationItem{
  display: flex;
  flex-direction: column;

  background-color: var(--white);

  padding: 7px;
  gap: 5px;

  border-radius: 7px;
}

.NotificationItem h3{
  font-size: 15px;
  color: var(--background-main);
  font-weight: 550;
}

.notification-top{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
}


.notification-body{
  font-size: 15px;
  font-weight: 400;
  color: var(--background-main);
}

.date{
  font-size: 15px;
  font-weight: 400;
  color: #545454;
}

.buttons-container{
  width: 100%;

  display: flex;

  gap: 7px;

  padding-top: 3px;
  padding-bottom: 5px;
}

.buttons-container button{
  width: 100%;
  height: 30px;
  color: var(--background-main);
  font-size: 13px;
  border-radius: 7px;

  border: 2px solid #939393;
}

.accept{
  background-color: rgb(49, 153, 49) !important;
  box-shadow: 0 4px 0 0 rgb(34, 105, 34) !important;
  border-color: rgb(34, 105, 34) !important;
  color: var(--white) !important;
}

.accept:hover{
  box-shadow: 0 4px 0 0 rgb(32, 84, 32) !important;
}

.accept:active{
  transform: translateY(4px) !important;
  box-shadow: 0 0px 0 0 rgb(32, 84, 32) !important;
}