.LeaderboardMember{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--white);

  padding: 5px;

  border-radius: 7px;
  border: 2px solid transparent;
  
  gap: 10px;
}

.LeaderboardMember .PointsStreak img, .Friend .PointsStreak img{
  height: 25px;
}

.member-container{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
}

.member-container svg{
  color: var(--ice-blue);
}

.member{
  color: var(--background-main);
}

.rank{
  background-color: gray;
  color: var(--white);
  min-width: 20px;
  min-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  font-size: 15px;
  font-weight: 500;

  margin-left: 2px;
}

.first .rank{
  color: #e5c450;
  background-color: var(--white);
}

.second .rank{
  color: #A4BBCE;
  background-color: var(--white);
}

.third .rank{
  color: #CD8D58;
  background-color: var(--white);
}

.isUser .rank{
  color: #1d3e49;
  background-color: var(--white);
}

.isUser .member{
  color: var(--white);
}

.isUser .PointsStreak .leaderboard-points{
  color: var(--white);
}

.rank-user-container{
  display: flex;
  flex-direction: row;
  gap: 7px;

  max-width: 150px;
  overflow: clip;
}

.first{
  border-color: #A8892E;
  background: linear-gradient(to right, #ffd84b 0%, #e9dc98 40%, #ffd84b 100%) ;
}

.second{
  border-color: #6d7c87;
  background: linear-gradient(to right, #A4BBCE 0%, #d7e8f4 40%, #A4BBCE 100%) ;
}

.third{
  border-color: #A87348;
  background: linear-gradient(to right, #CD8D58 0%, #F9C89F 40%, #CD8D58 100%) ;
}

.isUser{
  border-color: #2D7595 !important;
  background: #1d3e49 !important;
}

.first p, .second p, .third p{
  font-weight: 500 !important;
  color: var(--white);
}