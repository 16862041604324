.Feedback{
  color: var(--background-main);
  background-color: var(--white);
  box-shadow: 0 4px 0 0 #939393;
  cursor: pointer;
  
  height: 40px;
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  gap: 7px;
  
  border-radius: 10px;
  border: none;

  transition: all 0.1s ease-in-out;
}

.Feedback:hover {
  background-color: #b5b5b5;
}

.Feedback:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}