.Game{
  width: 40%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  transition: all 0.4s ease;
}

.fullWidthGame{
  width: 70%;
  max-width: 700px;
}

@media (max-width: 800px) {
  .Game{
    width: 100%;
    height: 70vh;
  }
}

@media (max-width: 450px) {
  .Game{
    height: 80vh;
  }
}
