.Instructions{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  padding: 15px;
  background-color: var(--white);
  border-radius: 10px;
}

.Instructions h1{
  color: var(--black);
  text-align: center;
  font-size: 18px;
  line-height: 35px;
  font-weight: 500;
  padding-bottom: 0px;
}

.Instructions span{
  font-weight: 700;
}

@media (max-width: 800px) {
  .Instructions h1{
    font-size: 12px;
    line-height: 25px;
  }
}

@media (max-width: 630px){
  .Instructions h1{
    font-size: 18px;
    line-height: 35px;
  }
}