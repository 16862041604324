.MessageSubmitModal{
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 10px;
  padding-bottom: 12px;
  background-color: var(--white);
  border-radius: 10px;

  width: 300px;

  position: absolute;
  left: 0px;
  bottom: 50px;

  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.35);

  z-index: 5;
}

.sectionContainer{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.MessageSubmitModal h2{
  font-size: 18px;
  color: var(--background-main);
  font-weight: 550;
}

.MessageSubmitModal h3{
  font-size: 15px;
  color: var(--background-main);
  font-weight: 450;
}

.MessageSubmitModal input,
.MessageSubmitModal textarea {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 10px;
  border-radius: 7px;
  outline-color: var(--background-main);

  background-color: #e5e5e5;

  transition: background-color 0.3s, border-color 0.3s;
}

.MessageSubmitModal textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 150px;
}

.MessageSubmitModal input:focus,
.MessageSubmitModal textarea:focus {
  border-color: var(--background-main);
}

.MessageSubmitModal input::placeholder,
.MessageSubmitModal textarea::placeholder {
  color: #a6a6a6;
}
.MessageSubmitModal button{
  margin-top: 5px;
}

@media (max-width: 630px) {
  .MessageSubmitModal{
    width: calc(100vw - 60px);
  }
}