.legal-page {
  color: var(--white);
  font-family: 'Lexend', sans-serif;
}

.legal-page h1 {
  color: var(--ice-blue);
  text-align: left;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #444;
}

.legal-page h2 {
  color: var(--white);
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.legal-page h3 {
  color: var(--white);
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
}

.legal-page p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: var(--white);
  font-size: 15px;
}

.legal-page ul {
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.legal-page li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
  color: var(--white);
  font-size: 15px;
}

.legal-page section {
  margin-bottom: 2rem;
}