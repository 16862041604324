.subscription-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 2rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;

  padding: 15px;
  background-color: var(--white);
  border-radius: 10px;

  max-width: 400px;

  gap: 10px;
}

.subscription-container h1{
  color: var(--background-main);
  padding-bottom: 5px;
}

.subscription-container p{
  color: var(--background-main);
}

@media (max-width: 450px) {
  .subscription-container {
    max-width: 100vw - 20px;
  }
}