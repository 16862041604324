.GameChoices{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  width: 100%;
  min-height: 0;

  padding-bottom: 5px;
}

@media (min-width: 1350px) {

  .GameChoices{
    gap: 20px;
  }
}