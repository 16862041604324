.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out forwards;
}

.share-modal-content {
  position: relative;
  background-color: var(--background-main);
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.share-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: var(--background-light);
  border-radius: 10px;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.share-modal-content h2 {
  color: var(--white);
  font-size: 22px;
  font-weight: 600;
}

.share-card {
  background-color: var(--background-light);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  border: 2px solid var(--white);
}

.share-header {
  text-align: center;
}

.share-header h3 {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
}

.share-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}


.stat-item {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.stat-item-daily {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.stat-label {
  color: var(--white);
  font-size: 14px;
  opacity: 0.8;
}

.stat-value {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
}

.stat-value.incorrect {
  color: var(--ice-blue);
}

.share-emoji-pattern {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.emoji {
  font-size: 24px;
}

.share-buttons {
  display: flex;
  gap: 10px;
}

.share-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;

  border: 2px solid var(--white);
}

.share-button:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.share-button.copy {
  background-color: var(--background-light);
  color: var(--white);
}

.share-button.download {
  background-color: var(--ice-blue);
  color: var(--white);
}

.share-button.copy:hover {
  background-color: #3a3d42;
}

.share-button.download:hover {
  background-color: #53ccff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .share-button span {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .share-modal-content {
    padding: 20px;
    width: 95%;
  }
  
  .share-stats {
    grid-template-columns: 1fr;
  }
} 