.PremiumModal {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.6);

  width: 100vw;
  height: 100vh;

  z-index: 1000;
}

.PremiumModal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;

  background-color: var(--background-main);
  color: var(--white);
  padding: 20px;
  border-radius: 10px;

  width: 380px;

  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.25)
}

.PremiumModal-container h2 {
  font-size: 25px;
  font-weight: 700;
  color: var(--accent-yellow);
  margin-bottom: 5px;
}

.premium-features {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  gap: 10px;
}

.premium-feature-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  background-color: var(--white);
  padding: 7px;
  border-radius: 7px;
}

.premium-feature-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--ice-blue);
}

.premium-feature-description {
  font-size: 12px;
  font-weight: 400;
  color: var(--background-main);
}

.premium-features h3 {
  font-size: 18px;
  font-weight: 600;
}

.premium-pricing {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 3px;
}

.premium-pricing h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
}

.premium-pricing p {
  font-size: 12px;
  opacity: 0.5;
}

@media (max-width: 500px) {
  .PremiumModal-container {
    margin: 15px;
  }

  .premium-feature-container:last-child {
    display: none;
  }
}