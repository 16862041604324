.PointsStreak{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  position: relative;
}

.PointsStreak img{
  height: 30px;
}

.PointsStreak p{
  font-weight: 450;
}

.leaderboard-points{
  color: var(--background-main);
}

.levelDesign{
  border: 2px solid white;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PointsStreak:hover .levelInfo{
  display: flex;
}

.levelInfo{
  display: none;

  position: absolute;
  top: -25px;
  right: 40%;

  z-index: 999;

  background-color: var(--white);
  color: var(--background-main) !important;
  padding: 5px;
  box-shadow:  0px 0px 4px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  font-size: 15px !important;
  text-wrap: nowrap;

  animation: fade-in 0.2s ease forwards;
}

@keyframes fade-in{
  from{
    opacity: 0;
  } to {
    opacity: 100;
  }
}