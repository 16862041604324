.countriesContainer{
  display: flex;
  gap: -5px;
}

.countriesContainer > * {
  margin-right: -13px;
}

.countriesContainer > *:last-child {
  margin-right: 0;
}

.gamemodeCountry{
  border: 2px solid rgb(166, 200, 221);
  height: 22px;
  width: 22px;
  border-radius: 20px;
}

.gamemodeSuperRegion{
  border: 2px solid rgb(166, 200, 221);
  background-color: rgb(36, 99, 131);
  height: 22px;
  width: 22px;
  padding: 2px;
  border-radius: 20px;
}

.moreCountries{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(36, 99, 131);
}

.moreCountries p{
  font-size: 11px;
  color: var(--white);
  margin-bottom: 1px;
  margin-left: 2px;
}