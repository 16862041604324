.Footer{
  display: flex;
  align-items: center;
  gap: 20px;
}

.Footer a{
  text-decoration: none;
  text-wrap: nowrap;

  width: 120px;

  text-align: end;
}

.Footer a, .Footer p{
  color: #686868;
}

.Footer a:hover{
  text-decoration: underline;
}

@media (max-width: 400px) {
  .Footer{
    gap: 10px;
  }
}