.Notifications{
  position: relative;
}

.Notifications button{
  color: var(--background-main);
  background-color: var(--white);
  box-shadow: 0 4px 0 0 #939393;
  
  height: 40px;
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  gap: 7px;
  
  border-radius: 10px;
  border: none;

  transition: all 0.1s ease-in-out;
}

.Notifications button:hover {
  background-color: #b5b5b5;
}

.Notifications button:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}

.notifications-button{
  position: relative;
}

.notifications-modal{
  position: absolute;
  top: 55px;
  left: 0px;

  z-index: 50;

  background-color: var(--background-light);

  box-shadow: 0px 0px 11px 6px rgba(0, 0, 0, 0.35);

  width: 400px;
  max-height: 400px;

  overflow: auto;

  scrollbar-width: none;
  -webkit-overflow-scrolling: none;

  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 7px;
  border-radius: 10px;
}

.new-notifs-count{
  position: absolute;
  top: -7px;
  right: -7px;

  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background-color: var(--danger-red);
  color: var(--white);
  height: 25px;
  width: 25px;

  font-size: 13px;
}

.no-notifications{
  width: 100%;
  height: 50px;

  background-color: var(--background-main);
  padding: 5px;
  border-radius: 7px;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 580px){
  .notifications-modal{
    width: calc(100vw - 60px);
  }
}