.GameAdminBar{
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.adminButton{
  width: 100%;
  border: none;
  border-radius: 7px;

  padding: 10px 5px;
}

.delete{
  background-color: var(--danger-red);
  color: var(--white);
}