.App {
  margin: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 50px);
  width: calc(100vw - 60px);
  gap: 20px;

  min-height: 0;

  position: relative;
}


@media (max-height: 800px) {
  .App{
    margin: 20px 20px 10px 20px;
    height: calc(100vh - 30px);
    width: calc(100vw - 40px);
  }
}

@media (max-width: 800px){
  .App{
    padding-bottom: 50px;
    height: unset;
  }
}

@media (max-width: 500px) {
  .App{
    margin: 15px 15px 5px 15px;
    width: calc(100vw - 30px);
  }
}